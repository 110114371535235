export const regKedouCN = /kedoucn\.com$/i;
export const regKedouFuwu = /kedoufuwu\.com$/i;
export const regXiaoHeiPao = /xiaoheipao\.com$/i;

const kedoucn = '京ICP备2020043473号';
const kedoufuwu = '京ICP备2020043473号';
const xiaoheipao = '京ICP备2020043473号';

export const isProductServer = () => {
  const { host } = window.location;
  return regKedouCN.test(host) || regXiaoHeiPao.test(host);
};

export const getIcpNo = () => {
  const { host } = window.location;

  if (regKedouCN.test(host)) {
    return kedoucn;
  }

  if (regXiaoHeiPao.test(host)) {
    return xiaoheipao;
  }

  return kedoufuwu;
};
