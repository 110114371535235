<template>
  <div :class="`page_footer section page-${$route.name}`">
    <div class="com_info">
      <div class="logo" />
      <div class="list_item" v-for="item in list" :key="item.key">
        <p class="title">{{ item.title }}</p>
        <ul class="des_list">
          <li v-for="ite in item.list" :key="ite">{{ ite }}</li>
        </ul>
      </div>
    </div>
    <div class="record_info">
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >
        {{icp}}
      </a>
      <span>© 2022-{{(new Date()).getFullYear()}} 北京店店赚科技有限公司</span>
      <a
        class="beianGov"
        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006877"
        target="_blank"
      >
        <span>沪公网安备 31010602006877号</span>
      </a>
    </div>
  </div>
</template>

<script>
import { getIcpNo } from '@/utils/server.ts';

export default {
  name: 'FooterView',
  created() {
    this.icp = getIcpNo();
  },
  data() {
    return {
      list: [
        { key: 'connect', title: '联系我们', list: ['18964411423', '工作日：9:00-19:00'] },
        { key: 'bus', title: '商务合作', list: ['微信：lvxiaozhou666', '邮箱：bd@lvzhoutech.com'] },
        { key: 'address', title: '公司地址', list: ['上海市静安区', '江场三路181号'] },
      ],
      icp: '',
    };
  },
};
</script>

<style lang="less" scoped>
.page_footer {
  width: 100%;
  height: 250px !important;
  background: #ffffff;
  .com_info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 48px;
    padding-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;

    .logo {
      position: relative;
      width: 95px;
      height: 32px;
      margin-right: 70px;
      background-image: url('./../../assets/img/kedou/heipaologo.png');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
    }

    .list_item {
      text-align: left;
      margin: 0 70px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 34px;
      }
      .des_list {
        margin-top: 13px;
        li {
          list-style: none;
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          line-height: 26px;
        }
      }
    }
  }
  .record_info {
    height: 70px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 70px;
    width: 100%;
    text-align: center;
    > * {
      display: inline-block;
      margin: 0 0 0 8px;
      line-height: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
    a {
      color: #999999;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .beianGov {
      > span {
        display: inline-block;
        padding: 0 0 0 20px;
        background-image: url(~@/assets/beian.png);
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: left center;
      }
    }
  }

  &.page-lawlion {
    .logo {
      width: 28px;
      margin-right: 137px;
      background-image: url('./../../assets/img/kedou/lawlion-logo.png');

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        color: #333;
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        transform: translate(32px, -50%);
        content: '法狮众服';
      }
    }
  }
}
</style>
