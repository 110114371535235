import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const pcRouteMap = {
  user: 'mkedou',
  ai: 'msmart',
  lvzhou: 'mlvzhou',
  lawyer: 'mlawyer',
  heipao: 'mheipao',
};
const mRouteMap = {
  mkedou: 'user',
  msmart: 'ai',
  mlvzhou: 'lvzhou',
  mlawyer: 'lawyer',
  mheipao: 'heipao',
};

const routes = [
  {
    path: '/',
    redirect: { path: '/kedou' },
  },
  {
    path: '/download',
    component: () => import(/* webpackChunkName: "download" */ '../views/DownloadView.vue'),
  },
  {
    path: '/kedou',
    redirect: { name: 'ai' },
    children: [
      // {
      //   path: 'user',
      //   name: 'user',
      //   component: () =>
      //     import(/* webpackChunkName: "kedou/user" */ '../views/kedou/HeiPao.vue'),
      //   meta: {
      //     index: 1,
      //   },
      // },
      // {
      //   path: 'lawyer',
      //   name: 'lawyer',
      //   component: () =>
      //     import(/* webpackChunkName: "kedou/lawyer" */ '../views/kedou/LawyerView.vue'),
      //   meta: {
      //     index: 2,
      //   },
      // },
      {
        path: 'ai',
        name: 'ai',
        component: () => import(/* webpackChunkName: "kedou/ai" */ '../views/kedou/AIView.vue'),
        meta: {
          index: 3,
        },
      },
      {
        path: 'lvzhou',
        name: 'lvzhou',
        component: () => import(/* webpackChunkName: "kedou/lvzhou" */ '../views/kedou/LvZhou.vue'),
        meta: {
          index: 4,
        },
      },
      {
        path: 'settle',
        name: 'settle',
        component: () =>
          import(/* webpackChunkName: "kedou/settle" */ '../views/kedou/SettleView.vue'),
        meta: {
          index: 5,
        },
      },
      {
        path: 'lawlion',
        name: 'lawlion',
        component: () =>
          // eslint-disable-next-line
          import(/* webpackChunkName: "kedou/lawlion" */ '../views/kedou/LawLion.vue'),
        meta: {
          index: 6,
        },
      },
    ],
  },
  {
    path: '/mobile',
    name: '蝌蚪',
    children: [
      {
        path: 'msmart',
        name: 'msmart',
        component: () =>
          import(/* webpackChunkName: "mobile/msmart" */ '../views/mobile/MobileView.vue'),
      },
      {
        path: 'mlvzhou',
        name: 'mlvzhou',
        component: () =>
          import(/* webpackChunkName: "mobile/mlvzhou" */ '../views/mobile/LvZhou.vue'),
      },
      // {
      //   path: 'mkedou',
      //   name: 'mkedou',
      //   component: () =>
      //     import(/* webpackChunkName: "mobile/mkedou" */ '../views/mobile/Heipao.vue'),
      // },
      {
        path: 'mheipao',
        name: 'mheipao',
        component: () =>
          import(/* webpackChunkName: "mobile/mheipao" */ '../views/mobile/Heipao.vue'),
      },
      {
        path: 'mlawlion',
        name: 'mlawlion',
        component: () =>
          import(/* webpackChunkName: "mobile/mlawlion" */ '../views/mobile/LawLion.vue'),
      },
      // {
      //   path: 'mlawyer',
      //   name: 'mlawyer',
      //   component: () =>
      //     import(/* webpackChunkName: "mobile/mlawyer" */ '../views/mobile/Lawyer.vue'),
      // },
      {
        path: 'mwhy',
        name: 'mwhy',
        component: () => import(/* webpackChunkName: "mobile/mwhy" */ '../views/mobile/Why.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (/^\/download/i.test(to.fullPath) || /^\/kedou\/settle/i.test(to.fullPath)) {
    next();
  } else if (store.state.isPc) {
    if (to?.name?.includes('m')) {
      router.replace({ name: mRouteMap[to.name] });
    } else {
      next();
    }
  } else if (!to?.name?.includes('m')) {
    router.replace({ name: pcRouteMap[to.name] });
  } else {
    next();
  }
});

export default router;
